import React from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
export const FacebookLink = ({ color = "secondary" }) => (
  <a
    href="https://www.facebook.com/getnaturethings"
    target="_blank"
    rel="noreferrer"
  >
    <FacebookIcon color={color} />
  </a>
);
export const LinkedinLink = ({ color = "secondary" }) => (
  <a
    href="https://www.linkedin.com/company/getnaturethings/"
    target="_blank"
    rel="noreferrer"
  >
    <LinkedInIcon color={color} />
  </a>
);
export const InstagramLink = ({ color = "secondary" }) => (
  <a
    href="https://www.instagram.com/getnaturethings/"
    target="_blank"
    rel="noreferrer"
  >
    <InstagramIcon color={color} />
  </a>
);
export const SocialLinks = ({ color = "secondary" }) => {
  return (
    <>
      <FacebookLink color={color} />
      <InstagramLink color={color} />
      <LinkedinLink color={color} />
    </>
  );
};
