import { Container, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { SocialLinks } from '../SocialLinks';

const styles = {
  link: {
    opacity: 1,
    cursor: 'pointer',
  },
};

const Footer = () => {
  const { t } = useTranslation();

  const linkProps = {
    mt: 1,
    color: 'text.green',
    component: Link,
    sx: styles.link,
  };

  const links = {
    row1: [
      {
        label: t('about'),
        href: '/about',
      },
      {
        label: t('contact_us'),
        href: '/contact_us',
      },
      {
        label: t('terms_of_use'),
        href: '/terms_of_use',
      },
      {
        label: t('privacy_policy'),
        href: '/privacy_policy',
      },
    ],
    // row2: [
    //   { href: "/articles", label: t("view_articles") },
    //   { href: "/contact_us", label: t("seller_signup") },
    //   { href: "/contact_us", label: t("corporate_enquiries") },
    //   { href: "/contact_us", label: t("contact_us") },
    // ],
  };

  return (
    <Container
      sx={{
        py: { xs: 3.5, sm: 8 },
        px: 4,
        mt: 4,
        mb: 2,
        backgroundColor: 'primary.purple',
        borderRadius: 2,
      }}
      maxWidth="1440px"
    >
      <Grid container justifyContent={'space-between'}>
        <Grid container item xs={6} md={2} direction={'column'}>
          <Image
            src="/assets/images/nt_logo.svg"
            width={40}
            height={40}
            layout="fixed"
            alt={t('nature_things')}
          />
          {links.row1.map((link, index) => (
            <Typography href={link.href} {...linkProps} key={index}>
              {link.label}
            </Typography>
          ))}
          <Grid mt={1}>
            <Typography>
              Contact us at <br />
              <Typography
                component="a"
                href="mailto:support@getnaturethings.engagebayservice.com"
                color="text.green"
                fontWeight="bold"
                sx={{
                  textDecoration: 'none',
                  wordBreak: 'break-all',
                }}
              >
                eva@naturethings.co
              </Typography>
            </Typography>
          </Grid>
          <Grid mt={2} container sx={{ columnGap: 1 }}>
            <SocialLinks color="secondary" />
          </Grid>
        </Grid>

        {/*<Grid container item xs={6} md={2} direction={"column"}>*/}
        {/*  {links.row2.map((link, index) => (*/}
        {/*    <Typography href={link.href} {...linkProps} key={index}>*/}
        {/*      {link.label}*/}
        {/*    </Typography>*/}
        {/*  ))}*/}
        {/*</Grid>*/}

        <Grid
          item
          xs={12}
          md={4}
          mt={1}
          container
          alignSelf={'flex-end'}
          sx={{ justifyContent: { xs: 'flex-start', md: 'flex-end' } }}
        >
          <Typography mr={4}>© All Nature Things Pte Ltd</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
